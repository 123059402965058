import { graphql } from 'gatsby';
import React from 'react';
import * as styles from '../pages/careers/careers.module.scss';
import CareerCard from '../components/career-card/CareerCard';
import TitleH2 from '../components/title-h2/TitleH2';
import Layout from '../components/layout/Layout';
import { ICareerNode, ICareersContainerData, ICareersContent } from '../types/careersTypes';

import { careersContentMaker, careersListMaker } from '../utils/careersHandlers';
import classNames from 'classnames';
import { Fade, Zoom, Slide } from 'react-reveal';

type CareersTemplateProps = {
   careersList: ICareerNode[],
   careersContent: ICareersContent
}

export const CareersTemplate: React.FC<CareersTemplateProps> = ({ careersList, careersContent }) => {
   const { background, title, subtitle, firstImage, benefitsBackground,
      headingOne, headingOneList, keysBackground,
      headingTwo, image, hiringBackground, headingThree
   } = careersContent.frontmatter;
   return (
      <main>
         <section className={styles.main} style={{ backgroundImage: `url(${background})` }}>
            <div className={classNames(classNames, styles.mainContainer, 'wrapper')}>
               <Fade delay={500}>
                  <h1 className={styles.mainTitle}>{title}</h1>
               </Fade>
               <Fade delay={500}>
                  <p className={styles.mainSubtitle}>
                     {subtitle}
                  </p>
               </Fade>
               <img className={styles.mainImage} src={firstImage} alt="Main sectiom" />
            </div>
         </section>

         <section className={styles.benefits} style={{ backgroundImage: `url(${benefitsBackground})` }}>
            <div className={classNames(classNames, styles.benefitsContainer, 'wrapper')}>
               <TitleH2 className={styles.benefitsTitle}>{headingOne}</TitleH2>
               <Zoom>
                  {headingOneList?.map((el, i) => <article className={styles.benefitsCard} key={i}>
                     <img className={styles.benefitsCardIcon} src={el.icon} alt="" aria-hidden="true" />
                     <h3 className={styles.benefitsCardTitle}>{el.title}</h3>
                     <p className={styles.benefitsCardDescription}>{el.description}</p>
                  </article>)}
               </Zoom>
            </div>
         </section>

         <section className={styles.features} style={{ backgroundImage: `url(${keysBackground})` }}>
            <div className={classNames(classNames, styles.featuresContainer, 'wrapper')}>
               <TitleH2>{headingTwo}</TitleH2>
               <div className={styles.featuresKeys}>
                  <Slide right>
                     <p dangerouslySetInnerHTML={{ __html: careersContent.html }} className={styles.featuresKeysText} />
                  </Slide>

                  <Zoom big>
                     <img className={styles.featuresKeysImg} src={image} alt="Features keys image" />
                  </Zoom>
               </div>
            </div>
         </section>

         <section className={styles.hiring} style={{ backgroundImage: `url(${hiringBackground})` }}>
            <div className={classNames(classNames, styles.hiringContainer, 'wrapper')}>
               <TitleH2>{headingThree}</TitleH2>
               <Zoom>
                  <div className={styles.hiringList}>
                     {careersList?.map((el, i) => <CareerCard
                        key={i}
                        career={{
                           location: el.node.frontmatter.location,
                           careerName: el.node.frontmatter.careerName,
                           path: el.node.frontmatter.path,
                           description: el.node.frontmatter.description
                        }}
                     />)}
                  </div>
               </Zoom>
            </div>
         </section>
      </main>
   );
};

type CareersContainerProps = {
   data: ICareersContainerData
}

const CareersContainer: React.FC<CareersContainerProps> = ({ data }) => {

   const { edges } = data.allMarkdownRemark;
   const careersList: ICareerNode[] = careersListMaker(edges);
   const careersContent = careersContentMaker(edges);

   return (
      <Layout>
         <CareersTemplate careersList={careersList} careersContent={careersContent[0].node} />
      </Layout>
   );
};

export const allCareersQuery = graphql`
  query {
	 allMarkdownRemark(
		  filter: {frontmatter: {templateKey: {in: ["careers-template", "career-template"]}}}
		) {
		  edges {
			 node {
				id
				html
				frontmatter {
				  templateKey
				  date(formatString: "MMMM DD, YYYY")
				  path
				  careerName
				  location
				  description

              background
				  title
                  subtitle
				  firstImage
              benefitsBackground
				  headingOne
				  headingOneList {
						icon
						title
						description
				  }
              keysBackground
				  headingTwo
				  image
              hiringBackground
				  headingThree
				}
			 }
		  }
		}
	 }
`;

export default CareersContainer;