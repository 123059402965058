import { CareersEdgeType } from "../types/careersTypes";

export const careersContentMaker = (edges: CareersEdgeType[]) => {
    return edges
        .filter((edge) => edge.node.frontmatter.templateKey === 'careers-template')
        .map((edge) => ({
            node: {
                ...edge.node,
                frontmatter: {
                    path: edge.node.frontmatter.path,
                    templateKey: edge.node.frontmatter.templateKey,
                    background: edge.node.frontmatter.background,
                    title: edge.node.frontmatter.title,
                    subtitle: edge.node.frontmatter.subtitle,
                    firstImage: edge.node.frontmatter.firstImage,
                    benefitsBackground: edge.node.frontmatter.benefitsBackground,
                    headingOne: edge.node.frontmatter.headingOne,
                    headingOneList: edge.node.frontmatter.headingOneList,
                    keysBackground: edge.node.frontmatter.keysBackground,
                    headingTwo: edge.node.frontmatter.headingTwo,
                    image: edge.node.frontmatter.image,
                    hiringBackground: edge.node.frontmatter.hiringBackground,
                    headingThree: edge.node.frontmatter.headingThree
                }
            }
        }));
}

export const careersListMaker = (edges: CareersEdgeType[]) => {
    return edges
        .filter((edge) => edge.node.frontmatter.templateKey === 'career-template')
        .map((edge) => ({
            node: {
                ...edge.node,
                frontmatter: {
                    location: edge.node.frontmatter.location,
                    careerName: edge.node.frontmatter.careerName,
                    path: edge.node.frontmatter.path,
                    description: edge.node.frontmatter.description,
                    templateKey: edge.node.frontmatter.templateKey
                }
            }
        }));
}